<template>
  <PflegeButton
    :ref="props.blok.link?.ref"
    v-editable="props.blok"
    :data-uid="props.blok._uid"
    :icon="props.blok.icon"
    :iconPosition="props.blok.iconPosition"
    :label="props.blok.label"
    :tag="useCalcedLink(props.blok) ? defineNuxtLinkTag : 'button'"
    :target="props.blok.link?.target"
    :title="props.blok.link ? props.blok.link.title : null"
    :to="useCalcedLink(props.blok)"
    :trackingLabel="trackingLabel"
    :variant="props.blok.type"
  />
</template>

<script setup>
import { PflegeButton } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})

const { trackClick, createTrackingLabel } = useTracking()
const { pageTrackingData } = useGaTrackingStore()
const mainComponent = inject('mainComponent', null)

const trackingLabel = computed(() => {
  return `btn_${createTrackingLabel(props.blok.label)}`
})

if (mainComponent) {
  const mainComponentLabel = ref(mainComponent.mainComponent.value)
  if (props.blok.mainComponentTrackingLabel?.trim().length > 0) {
    mainComponentLabel.value = props.blok.mainComponentTrackingLabel
  }
  trackClick('button', mainComponentLabel.value, props.blok.label, pageTrackingData)
}

const defineNuxtLinkTag = computed(() => {
  return defineNuxtLink({})
})
</script>
